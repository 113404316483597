"use strict";

function _readOnlyError(name) { throw new TypeError("\"" + name + "\" is read-only"); }
/* ==========================================================================
Main
========================================================================== */
/* E
 * Description :
 * - Scripts de base
 * Auteur :
 * - CEF / FUE
 * Historique :
 * - 2020-04-02 : Création
 * - 2021-09-30 : remplacement de faq et collapse par RunAccordion
 * Sommaire :
 * |x| table
 * |x| dropdown
 * |x| RunAccordion
 */

(function () {
  //pour des questions de performance, on teste l'évenement scroll en passif.
  //mais ceci n'est pas possible pour tes anciens navigateurs https://github.com/WICG/EventListenerOptions/blob/gh-pages/explainer.md
  // Test via a getter in the options object to see if the passive property is accessed
  var supportsPassive = false;
  try {
    var opts = Object.defineProperty({}, 'passive', {
      get: function get() {
        true, _readOnlyError("supportsPassive");
      }
    });
    window.addEventListener('testPassive', null, opts);
    window.removeEventListener('testPassive', null, opts);
  } catch (e) {}
  var table = {
    onLoad: function onLoad() {
      // On récupère toutes les tables
      //const tables = [...document.querySelectorAll('.cc table:not(.amc-toolbar-table)')];//Non compatible avec IE11 et Babel (spread sur une nodelist)
      var tables = Array.prototype.slice.call(document.querySelectorAll('.cc table:not(.amc-toolbar-table)'));
      if (tables.length) {
        // Si pas de slide
        tables.filter(function (el) {
          return el.classList.contains('noslider');
        }).map(function (el) {
          table.setNoSlider(el);
        });
        // On filtre celles avec 1 entrée horizontale
        tables.filter(function (el) {
          return el.classList.contains('one-entry');
        }).map(function (el) {
          table.setOneEntry(el);
        });

        // On filtre celles avec 1 entrée verticale
        tables.filter(function (el) {
          return el.classList.contains('one-entry-v');
        }).map(function (el) {
          table.setOneEntryVertical(el);
        });

        // On filtre celles avec 2 entrées
        tables.filter(function (el) {
          return el.classList.contains('two-entry');
        }).map(function (el) {
          table.setTwoEntries(el);
        });

        // On filtre celles en liste
        tables.filter(function (el) {
          return el.classList.contains('list') && !el.classList.contains('noslider');
        }).map(function (el) {
          table.setList(el);
        });
        // On filtre celles en avec multiples entrées et dropdown
        tables.filter(function (el) {
          return el.classList.contains('entries-dropdown');
        }).map(function (el) {
          table.setMultiEntriesDropdown(el);
        });
      }
    },
    setNoSlider: function setNoSlider(target) {
      // On gère le stticky sur le thead
      table.setSticky(target);
    },
    setOneEntry: function setOneEntry(target) {
      // On ajoute la classe desktop pour cacher le tableau en responsive
      //target.classList.add('desktop');
      target.className += ' desktop'; //classList bug avec IE

      // On crée notre object qui servira à la construction du tableau responsive
      var responsiveTableData = {};

      // On récupère le caption, le summary et toutes les lignes du body
      var caption = target.querySelector('caption') ? target.querySelector('caption').innerHTML : '';
      var summary = target.getAttribute('summary');
      //const tbodyRows = [...target.querySelectorAll('tbody > tr')];//Non compatible avec IE11 et Babel (spread sur une nodelist)
      var tbodyRows = Array.prototype.slice.call(target.querySelectorAll('tbody > tr'));

      //responsiveTableData = { caption, summary };
      responsiveTableData = {
        caption: caption,
        summary: summary
      };
      responsiveTableData.tbody = [];

      // Pour chaque ligne on crée une entrée dans notre object avec les valeurs head et content du tbody
      //for (const row of tbodyRows) {
      tbodyRows.forEach(function (row) {
        var entry = {};
        var head = '';
        var cells = [];
        var children = Array.prototype.slice.call(row.children);
        children.forEach(function (el) {
          if (el.tagName === 'TH') {
            head = el.innerHTML;
          }
          if (el.tagName === 'TD') {
            var tdContent = '';
            var tdClass = '';
            if (el.innerHTML !== '') {
              tdContent = el.innerHTML;
            } else if (el.innerHTML === '' && el.className !== '') {
              tdClass = el.className;
            }
            cells.push({
              content: tdContent,
              "class": tdClass
            });
          }
        });

        //entry = { head, content };
        entry = {
          head: head,
          cells: cells
        };
        responsiveTableData.tbody.push(entry);
      });

      // On crée notre tableau responsive
      var newTable = document.createElement('table');
      //newTable.classList = 'one-entry mobile';
      newTable.className = 'one-entry mobile'; //classList bug avec IE
      newTable.setAttribute('summary', responsiveTableData.summary);

      // On ajoute le caption
      var newCaption = document.createElement('caption');
      newCaption.innerHTML = responsiveTableData.caption;
      newTable.appendChild(newCaption);

      // On ajoute le tbody
      var newBody = document.createElement('tbody');
      newTable.appendChild(newBody);

      // On boucle sur chaque ligne du tableau
      //for (const el of responsiveTableData.tbody) {
      responsiveTableData.tbody.forEach(function (el, index) {
        // On crée les différents éléments
        var row = document.createElement('tr');
        var cell = document.createElement('td');
        var head = document.createElement('span');
        var content;

        //head.classList = 'cell__head';
        head.className += ' cell__head'; //classList bug avec IE
        head.setAttribute('role', 'columnheader');

        // On récupère les valeurs des cellules
        head.innerHTML = el.head;

        // On pour chaque cellule le contenu
        cell.appendChild(head);
        responsiveTableData.tbody[index].cells.forEach(function (el) {
          content = document.createElement('span');
          if (el.content === '' && el["class"] !== '') {
            content.className = el["class"];
          }
          if (el.content !== '') {
            content.innerHTML = el.content;
          }
          //content.classList = 'cell__content';
          content.className += ' cell__content'; //classList bug avec IE
          content.setAttribute('role', 'cell');
          cell.appendChild(content);
        });

        // On ajoute les cellules dans la ligne
        row.appendChild(cell);

        // On ajoute tout dans le tbody
        newBody.appendChild(row);
      });

      // On ajoute le tableau responsive après le tableau d'origine
      target.parentNode.insertBefore(newTable, target.nextElementSibling);
    },
    setOneEntryVertical: function setOneEntryVertical(target) {
      // On ajoute la classe desktop pour cacher le tableau en responsive
      //target.classList.add('desktop');
      var currentClass = target.className;
      target.className += ' desktop'; //classList bug avec IE

      // On crée notre object qui servira à la construction du tableau responsive
      var responsiveTableData = {};

      // On récupère le caption, le summary et toutes les lignes du body
      var caption = target.querySelector('caption').innerHTML;
      var summary = target.getAttribute('summary');
      //const theadCells = [...target.querySelectorAll('thead > tr > th')];//Non compatible avec IE11 et Babel (spread sur une nodelist)
      var theadCells = Array.prototype.slice.call(target.querySelectorAll('thead > tr > th'));
      //const tbodyCells = [...target.querySelectorAll('tbody > tr > td')];//Non compatible avec IE11 et Babel (spread sur une nodelist)
      var tbodyCells = Array.prototype.slice.call(target.querySelectorAll('tbody > tr > td'));

      //responsiveTableData = { caption, summary };
      responsiveTableData = {
        caption: caption,
        summary: summary
      };
      responsiveTableData.cells = [];
      for (var i = 0; i < theadCells.length; i += 1) {
        var entry = {};
        var head = theadCells[i].innerHTML;
        var content = tbodyCells[i].innerHTML;
        //entry = { head, content };
        entry = {
          head: head,
          content: content
        };
        responsiveTableData.cells.push(entry);
      }

      // On crée notre tableau responsive
      var newTable = document.createElement('table');
      //newTable.classList = 'one-entry mobile';
      newTable.className = currentClass + ' mobile'; //classList bug avec IE
      newTable.setAttribute('summary', responsiveTableData.summary);

      // On ajoute le caption
      var newCaption = document.createElement('caption');
      newCaption.innerHTML = responsiveTableData.caption;
      newTable.appendChild(newCaption);

      // On ajoute le tbody
      var newBody = document.createElement('tbody');
      newTable.appendChild(newBody);

      // On boucle sur chaque ligne du tableau
      //for (const el of responsiveTableData.cells) {
      responsiveTableData.cells.forEach(function (el) {
        // On crée les différents éléments
        var row = document.createElement('tr');
        var cell = document.createElement('td');
        var head = document.createElement('span');
        var content = document.createElement('span');

        //head.classList = 'cell__head';
        head.className = 'cell__head'; //classList bug avec IE
        head.setAttribute('role', 'columnheader');
        //content.classList = 'cell__content';
        head.className = 'cell__content'; //classList bug avec IE
        content.setAttribute('role', 'cell');

        // On récupère les valeurs des cellules
        head.innerHTML = el.head;
        content.innerHTML = el.content;

        // On pour chaque cellule le contenu
        cell.appendChild(head);
        cell.appendChild(content);

        // On ajoute les cellules dans la ligne
        row.appendChild(cell);

        // On ajoute tout dans le tbody
        newBody.appendChild(row);
      });

      // On ajoute le tableau responsive après le tableau d'origine
      target.parentNode.insertBefore(newTable, target.nextElementSibling);
    },
    setTwoEntries: function setTwoEntries(target) {
      // On ajoute la classe desktop pour le cacher en responsive
      //target.classList.add('desktop');
      target.className += ' desktop'; //classList bug avec IE

      // On crée notre object qui servira à la construction du tableau responsive
      var responsiveTableData = {};

      // On récupère le caption, le summary, le thead et toutes les lignes du tbody
      var caption = target.querySelector('caption') ? target.querySelector('caption').innerHTML : '';
      var summary = target.getAttribute('summary');
      //const theadCols = [...target.querySelectorAll('thead > tr > th[scope="col"]')];//Non compatible avec IE11 et Babel (spread sur une nodelist)
      var theadCols = Array.prototype.slice.call(target.querySelectorAll('thead > tr > th[scope="col"]'));
      //const tbodyRows = [...target.querySelectorAll('tbody > tr')];//Non compatible avec IE11 et Babel (spread sur une nodelist)
      var tbodyRows = Array.prototype.slice.call(target.querySelectorAll('tbody > tr'));

      //responsiveTableData = { caption, summary };
      responsiveTableData = {
        caption: caption,
        summary: summary
      };
      responsiveTableData.thead = [];
      responsiveTableData.tbody = [];

      // Pour chaque colonne du thead on ajoute l'entrée dans notre objet
      theadCols.map(function (el) {
        if (el.innerHTML === '') {} else {
          var entry = {};
          entry = el.innerHTML;
          responsiveTableData.thead.push(entry);
        }
      });

      // Pour chaque ligne du tbody on récupère s'il y a un th dedans
      //for (const [index, row] of tbodyRows.entries()) {
      var _loop = function _loop(index) {
        var row = tbodyRows[index];
        var rowHead = row.querySelector('th');
        var rowHeadRowspan = '';
        if (rowHead !== null) {
          rowHeadRowspan = rowHead.rowSpan;
        }
        if (rowHead !== null) {
          rowHead = rowHead.innerHTML;
        }

        // Ensuite on récupère tous les td de chaque ligne
        //  const cells = [...row.querySelectorAll('td')];//Non compatible avec IE11 et Babel (spread sur une nodelist)
        var cells = Array.prototype.slice.call(row.querySelectorAll('td'));
        responsiveTableData.tbody.push({
          head: rowHead,
          rowspan: rowHeadRowspan,
          cells: []
        });

        // Pour chaque td on récupère le colspan, le contenu et sa classe
        cells.map(function (el) {
          var cellColspan = el.colSpan;
          var cellContent = el.innerHTML;
          var cellClass = el.className;
          var cell = {
            colspan: cellColspan,
            content: cellContent,
            "class": cellClass
          };

          // Si le colspan est supérieur à 1 on duplique la même entrée sur les x lignes concernées
          if (cell.colspan > 1) {
            for (var i = 0; i < cell.colspan; i++) {
              responsiveTableData.tbody[index].cells.push(cell);
            }
          }
          // Sinon on poursuit normalement
          else {
            responsiveTableData.tbody[index].cells.push(cell);
          }
        });
      };
      for (var index = 0; index < tbodyRows.length; index++) {
        _loop(index);
      }

      // On crée et on remplit l'élement slider
      table.createSlider(target, responsiveTableData);

      // On gère le stticky sur le thead
      table.setSticky(target);
    },
    setList: function setList(target) {
      // On ajoute la classe desktop pour le cacher en responsive
      //target.classList.add('desktop');
      target.className += ' desktop'; //classList bug avec IE

      // On crée notre object qui servira à la construction du tableau responsive
      var responsiveTableData = {};

      // On récupère le caption, le summary, le thead et toutes les lignes du tbody
      var caption = target.querySelector('caption') ? target.querySelector('caption').textContent : '';
      var summary = target.getAttribute('summary');
      //const theadCols = [...target.querySelectorAll('thead > tr > th:not(:first-child)')];//Non compatible avec IE11 et Babel (spread sur une nodelist)
      var theadCols = Array.prototype.slice.call(target.querySelectorAll('thead > tr > th'));
      //let tbodyRows = [...target.querySelectorAll('tbody > tr')];//Non compatible avec IE11 et Babel (spread sur une nodelist)
      var tbodyRows = Array.prototype.slice.call(target.querySelectorAll('tbody > tr'));

      //responsiveTableData = { caption, summary };
      responsiveTableData = {
        caption: caption,
        summary: summary
      };
      responsiveTableData.thead = [];
      responsiveTableData.tbody = [];

      // Pour chaque colonne du thead on ajoute l'entrée dans notre objet
      theadCols.map(function (el) {
        var entry = {};
        entry = el.innerHTML;
        responsiveTableData.thead.push(entry);
      });

      //tbodyRows = [...target.querySelectorAll('tbody > tr')];//Non compatible avec IE11 et Babel (spread sur une nodelist)
      tbodyRows = Array.prototype.slice.call(target.querySelectorAll('tbody > tr'));
      var tempRowCells = {
        cells: [],
        index: ''
      };
      // Pour chaque ligne du tbody on récupère s'il y a un th dedans
      //for (const [index, row] of tbodyRows.entries()) {
      var _loop2 = function _loop2(index) {
        var row = tbodyRows[index];
        var rowHead = row.querySelector('th');
        var tooltip;
        var rowHeadScope;
        var rowHeadHeaders;
        if (rowHead !== null) {
          rowHeadScope = row.querySelector('th').scope;
          rowHeadHeaders = row.querySelector('th').headers;
          rowHead = rowHead.innerHTML;
          tooltip = row.querySelector('th div[class*=tooltip]');
          if (tooltip !== null) {
            table.dropdown(row, tooltip);
          }
        }
        if (tooltip !== undefined) {
          responsiveTableData.tbody.push({
            head: rowHead,
            tooltip: true,
            scope: rowHeadScope,
            headers: rowHeadHeaders,
            cells: []
          });
        } else {
          responsiveTableData.tbody.push({
            head: rowHead,
            tooltip: false,
            scope: rowHeadScope,
            headers: rowHeadHeaders,
            cells: []
          });
        }

        // Ensuite on récupère tous les td de chaque ligne
        //  const cells = [...row.querySelectorAll('td')];//Non compatible avec IE11 et Babel (spread sur une nodelist)
        var cells = Array.prototype.slice.call(row.querySelectorAll('td'));
        // Pour chaque td on récupère le colspan, le contenu, sa classe, id
        cells.map(function (el) {
          var cellColspan = el.colSpan;
          var cellRowSpan = el.rowSpan;
          var cellContent = el.innerHTML;
          var cellClass = el.className;
          var cellID = el.id;
          var cellHeaders = el.headers;
          var cell = {
            colspan: cellColspan,
            rowspan: cellRowSpan,
            content: cellContent,
            "class": cellClass,
            id: cellID,
            headers: cellHeaders
          };
          // Si le colspan est supérieur à 1 on duplique la même entrée sur les x lignes concernées

          if (cell.colspan > 1) {
            for (var i = 0; i < cell.colspan; i++) {
              responsiveTableData.tbody[index].cells.push(cell);
            }
          } else if (cell.rowspan > 1) {
            responsiveTableData.tbody[index].cells.push(cell);
            tempRowCells.cells.push(cell);
            tempRowCells.index = index + 1;
          }
          // Sinon on poursuit normalement
          else {
            console.log('ici');
            responsiveTableData.tbody[index].cells.push(cell);
          }
        });
      };
      for (var index = 0; index < tbodyRows.length; index++) {
        _loop2(index);
      }
      if (tempRowCells.cells.length > 0) {
        for (tempRowCells.index; tempRowCells.index <= tempRowCells.cells[0].rowspan; tempRowCells.index++) {
          responsiveTableData.tbody[tempRowCells.index].cells.push(tempRowCells.cells[0]);
        }
      }
      table.createSlider(target, responsiveTableData);

      // On ajoute la gestion du sticky sur le thead
      table.setSticky(target);
    },
    setMultiEntriesDropdown: function setMultiEntriesDropdown(target) {
      // On ajoute la classe desktop pour le cacher en responsive
      //target.classList.add('desktop');
      target.className += ' desktop'; //classList bug avec IE

      // On crée notre object qui servira à la construction du tableau responsive
      var responsiveTableData = {};

      // On récupère le caption, le summary, le thead et toutes les lignes du tbody
      var caption = target.querySelector('caption') ? target.querySelector('caption').textContent : '';
      var summary = target.getAttribute('summary');
      //const theadCols = [...target.querySelectorAll('thead > tr > th:not(:first-child)')];//Non compatible avec IE11 et Babel (spread sur une nodelist)
      var theadCols = Array.prototype.slice.call(target.querySelectorAll('thead > tr > th:not(:first-child)'));
      //let tbodyRows = [...target.querySelectorAll('tbody > tr')];//Non compatible avec IE11 et Babel (spread sur une nodelist)
      var tbodyRows = Array.prototype.slice.call(target.querySelectorAll('tbody > tr'));

      //responsiveTableData = { caption, summary };
      responsiveTableData = {
        caption: caption,
        summary: summary
      };
      responsiveTableData.thead = [];
      responsiveTableData.tbody = [];

      // Pour chaque colonne du thead on ajoute l'entrée dans notre objet
      theadCols.map(function (el) {
        var entry = {};
        entry = el.innerHTML;
        responsiveTableData.thead.push(entry);
      });

      //tbodyRows = [...target.querySelectorAll('tbody > tr')];//Non compatible avec IE11 et Babel (spread sur une nodelist)
      tbodyRows = Array.prototype.slice.call(target.querySelectorAll('tbody > tr'));

      // Pour chaque ligne du tbody on récupère s'il y a un th dedans
      //for (const [index, row] of tbodyRows.entries()) {
      var _loop3 = function _loop3(index) {
        var row = tbodyRows[index];
        var rowHead = row.querySelector('th');
        var tooltip;
        if (rowHead !== null) {
          rowHead = rowHead.innerHTML;
          tooltip = row.querySelector('th div[class*=tooltip]');
          if (tooltip !== null) {
            table.dropdown(row, tooltip);
          }
        }
        if (tooltip !== undefined) {
          responsiveTableData.tbody.push({
            head: rowHead,
            tooltip: true,
            cells: []
          });
        } else {
          responsiveTableData.tbody.push({
            head: rowHead,
            tooltip: false,
            cells: []
          });
        }

        // Ensuite on récupère tous les td de chaque ligne
        //  const cells = [...row.querySelectorAll('td')];//Non compatible avec IE11 et Babel (spread sur une nodelist)
        var cells = Array.prototype.slice.call(row.querySelectorAll('td'));

        // Pour chaque td on récupère le colspan, le contenu et sa classe
        cells.map(function (el) {
          var cellColspan = el.colSpan;
          var cellContent = el.innerHTML;
          var cellClass = el.className;
          var cell = {
            colspan: cellColspan,
            content: cellContent,
            "class": cellClass
          };

          // Si le colspan est supérieur à 1 on duplique la même entrée sur les x lignes concernées
          if (cell.colspan > 1) {
            for (var i = 0; i < cell.colspan; i++) {
              responsiveTableData.tbody[index].cells.push(cell);
            }
          }
          // Sinon on poursuit normalement
          else {
            responsiveTableData.tbody[index].cells.push(cell);
          }
        });
      };
      for (var index = 0; index < tbodyRows.length; index++) {
        _loop3(index);
      }
      table.createSlider(target, responsiveTableData);

      // On ajoute la gestion du sticky sur le thead
      table.setSticky(target);
    },
    createSlider: function createSlider(target, data) {
      // On créé le slider pour la version responsive
      var newSliderWrapper = document.createElement('div');
      var newSlider = document.createElement('ul');

      // On crée un header sticky pour l'afficher en dehors du slider
      var stickyHeader = document.createElement('div');

      // On crée le caption s'il existe
      var sliderCaption = document.createElement('h2');
      sliderCaption.innerHTML = data.caption;

      // On ajoute les classes correspondantes
      //newSliderWrapper.classList = 'table__slider__wrapper';
      newSliderWrapper.className = 'table__slider__wrapper'; //classList bug avec IE
      //newSlider.classList = 'table__list';
      newSlider.className += ' table__list'; //classList bug avec IE
      //stickyHeader.classList = 'table__sticky__header';
      stickyHeader.className = 'table__sticky__header'; //classList bug avec IE

      if (data.thead.length > 0) {
        // On crée autant de slide qu'il y a de colonnes dans le thead moins la 1ère cellule
        for (var i = 0; i < data.thead.length; i++) {
          var newSlide = document.createElement('li');

          // On créé un nouveau tableau par slide
          var newTable = document.createElement('table');
          //newTable.classList = 'two-entry mobile';
          newTable.className = 'two-entry mobile'; //classList bug avec IE
          newTable.setAttribute('summary', data.summary);

          // On ajoute le thead
          var newThead = document.createElement('thead');
          var newTheadRow = document.createElement('tr');
          var newTheadTh = document.createElement('th');

          // En responsive le thead aura toujours un colspan de 2
          newTheadTh.setAttribute('colspan', '2');
          newTheadTh.innerHTML = data.thead[i];
          newTheadRow.appendChild(newTheadTh);
          newThead.appendChild(newTheadRow);

          // On ajoute le tbody
          var newTbody = document.createElement('tbody');

          // On créé toutes les lignes
          for (var j = 0; j < data.tbody.length; j++) {
            var newTbodyRow = document.createElement('tr');
            var newTbodyHead = document.createElement('th');
            var newTbodyContent = document.createElement('td');

            // Pour chaque ligne du tbody on regarde si le th n'est pas null
            if (data.tbody[j].head !== null) {
              newTbodyHead.innerHTML = data.tbody[j].head;
              if (data.tbody[j].rowspan !== undefined) {
                newTbodyHead.rowSpan = data.tbody[j].rowspan;
              }
              if (data.tbody[j].scope !== undefined) {
                newTbodyHead.scope = data.tbody[j].scope;
              }
              if (data.tbody[j].headers !== undefined) {
                newTbodyHead.headers = data.tbody[j].headers;
              }
              newTbodyRow.appendChild(newTbodyHead);
            }

            // On regarde également que la cellule existe bien
            if (data.tbody[j].cells[i] !== undefined) {
              newTbodyContent.innerHTML = data.tbody[j].cells[i].content;
              if (data.tbody[j].cells[i].headers !== '') {
                newTbodyContent.headers = data.tbody[j].cells[i].headers;
              }

              // Si elle existe et qu'elle a une classe "groupe" on ajoute le colspan correspondant
              if (data.tbody[j].cells[i]["class"] === 'group') {
                newTbodyContent.colSpan = data.tbody[j].cells[i].colspan;
                newTbodyContent.id = data.tbody[j].cells[i].id;
              }
              if (data.tbody[j].cells[i]["class"] === 'check') {
                //newTbodyContent.classList = 'check';
                newTbodyContent.className = 'check'; //classList bug avec IE
              }
            }

            // On ajoute ensuite tout cela dans le tbody
            newTbodyRow.appendChild(newTbodyContent);
            newTbody.appendChild(newTbodyRow);
          }

          // On ajoute le thead, tbody dans le nouveau tableau
          newTable.appendChild(newThead);
          newTable.appendChild(newTbody);

          // On ajoute le tablea dans la slide, qui elle même est ajoutée au slider
          newSlide.appendChild(newTable);
          newSlider.appendChild(newSlide);

          // On ajoute enfin le slider dans son wrapper et le header sticky pour le responsive
          newSliderWrapper.appendChild(newSlider);
          newSliderWrapper.prepend(stickyHeader);
          newSliderWrapper.prepend(sliderCaption);

          // On ajoute enfin le wrapper après le tableau d'origine
          target.parentNode.insertBefore(newSliderWrapper, target.nextElementSibling);
        }
      }
      if (data.thead.length === 0 && data.tbody.head !== '') {
        for (var _i = 0; _i < data.tbody.length; _i++) {
          var _newSlide = document.createElement('li');

          // On créé un nouveau tableau par slide
          var _newTable = document.createElement('table');
          //newTable.classList = 'two-entry mobile';
          _newTable.className = 'two-entry mobile'; //classList bug avec IE
          _newTable.setAttribute('summary', data.summary);

          // On ajoute le thead
          var _newThead = document.createElement('thead');
          var _newTheadRow = document.createElement('tr');
          var _newTheadTh = document.createElement('th');

          // En responsive le thead aura toujours un colspan de 2
          _newTheadTh.setAttribute('colspan', '2');
          _newTheadTh.innerHTML = data.tbody[_i].head;
          _newTheadRow.appendChild(_newTheadTh);
          _newThead.appendChild(_newTheadRow);

          // On ajoute le tbody
          var _newTbody = document.createElement('tbody');

          // On créé toutes les lignes

          var _newTbodyRow = document.createElement('tr');
          var _newTbodyContent = document.createElement('td');

          // Pour chaque ligne du tbody on regarde si le th n'est pas nu
          // On regarde également que la cellule existe bien
          for (var _j = 0; _j < data.tbody[_i].cells.length; _j++) {
            if (data.tbody[_i].cells[_j] !== undefined) {
              _newTbodyContent.innerHTML = data.tbody[_i].cells[_j].content;

              // Si elle existe et qu'elle a une classe "groupe" on ajoute le colspan correspondant
              if (data.tbody[_i].cells[_j]["class"] === 'group') {
                _newTbodyContent.colSpan = data.tbody[_i].cells[_i].colspan;
                _newTbodyContent.id = data.tbody[_i].cells[_i].id;
              }
              if (data.tbody[_i].cells[_j]["class"] === 'check') {
                //newTbodyContent.classList = 'check';
                _newTbodyContent.className = 'check'; //classList bug avec IE
              }
            }
          }

          // On ajoute ensuite tout cela dans le tbody
          _newTbodyRow.appendChild(_newTbodyContent);
          _newTbody.appendChild(_newTbodyRow);

          // On ajoute le thead, tbody dans le nouveau tableau
          _newTable.appendChild(_newThead);
          _newTable.appendChild(_newTbody);

          // On ajoute le tablea dans la slide, qui elle même est ajoutée au slider
          _newSlide.appendChild(_newTable);
          newSlider.appendChild(_newSlide);

          // On ajoute enfin le slider dans son wrapper et le header sticky pour le responsive
          newSliderWrapper.appendChild(newSlider);
          newSliderWrapper.prepend(stickyHeader);
          newSliderWrapper.prepend(sliderCaption);

          // On ajoute enfin le wrapper après le tableau d'origine
          target.parentNode.insertBefore(newSliderWrapper, target.nextElementSibling);
        }
      }

      // On initialise le slider
      table.initSlider(newSliderWrapper, data);
    },
    initSlider: function initSlider(target, data) {
      var tableList = target.querySelector('.table__list');
      var changeStickyHeader = function changeStickyHeader() {
        table.setStickyMobile(target, slider.getInfo().index, data);
      };
      var slider = tns({
        container: tableList,
        items: 1,
        slideBy: 'page',
        autoplay: false,
        controls: false,
        autoHeight: true,
        arrowKeys: true,
        mouseDrag: false,
        touch: true,
        nav: true,
        center: true,
        edgePadding: 0,
        responsive: {
          780: {
            disable: true,
            items: 1
          }
        },
        rewind: true,
        speed: 500,
        onInit: changeStickyHeader
      });
      table.sliderDropdown(tableList, slider);
      slider.events.on('indexChanged', changeStickyHeader);
    },
    setSticky: function setSticky(target) {
      var thead = target.querySelector('thead');
      if (thead === null) {
        return;
      }
      //const theadCells = [...thead.querySelectorAll('tr > th')];//Non compatible avec IE11 et Babel (spread sur une nodelist)
      var theadCells = Array.prototype.slice.call(thead.querySelectorAll('tr > th'));
      var header = document.querySelector('#ei_tpl_head');
      var navigation = document.querySelector('#ei_tpl_menuPrincipal');
      var firstRow = target.querySelector('tbody > tr:first-child');
      var lastRow = target.querySelector('tbody > tr:last-child');
      var sticky = document.createElement('div');
      var fullHeader;

      //Si on est sur l'APP
      if (window.location.href.indexOf('mobile') > -1) {
        fullHeader = 0;
        //Si sur CM, pas app
      } else if (window.location.href.indexOf('creditmutuel') > -1 && window.location.href.indexOf('mobile') === -1) {
        fullHeader = header.offsetHeight;
      }
      // Pour CIC, pas app
      else {
        fullHeader = header.offsetHeight + navigation.offsetHeight;
      }
      sticky.setAttribute('aria-hidden', 'true');
      sticky.setAttribute('role', 'presentation');

      //sticky.classList = 'sticky__header';
      sticky.className = 'sticky__header'; //classList bug avec IE

      for (var i = 0; i < theadCells.length; i++) {
        var newCell = document.createElement('div');
        var cellWidth = theadCells[i].offsetWidth;
        newCell.style.width = cellWidth + 'px';
        newCell.innerHTML = theadCells[i].innerHTML;
        sticky.appendChild(newCell);
      }
      target.parentNode.insertBefore(sticky, target);

      //const newStickyCells = [...sticky.querySelectorAll('div')];//Non compatible avec IE11 et Babel (spread sur une nodelist)
      var newStickyCells = Array.prototype.slice.call(sticky.querySelectorAll('div'));
      var theadPos = thead.getBoundingClientRect().top - fullHeader;
      var firstRowPos = firstRow.getBoundingClientRect().top - fullHeader;
      var lastRowPos = lastRow.getBoundingClientRect().top - fullHeader;

      // Quand on arrive au niveau du thead du tableau, on affiche le header sticky
      if (theadPos < 0) {
        sticky.style.display = 'flex';
        sticky.style.position = 'fixed';
        sticky.style.top = fullHeader + 'px';
      }

      // Quand on arrive à la dernière ou première ligne du tableau on le cache
      if (lastRowPos < 0 || firstRowPos > 0) {
        sticky.style.display = 'none';
      }
      window.addEventListener('scroll', function () {
        var theadPos = thead.getBoundingClientRect().top - fullHeader;
        var firstRowPos = firstRow.getBoundingClientRect().top - fullHeader;
        var lastRowPos = lastRow.getBoundingClientRect().top - fullHeader;

        // Quand on arrive au niveau du thead du tableau, on affiche le header sticky
        if (theadPos < 0) {
          sticky.style.display = 'flex';
          sticky.style.position = 'fixed';
          sticky.style.top = fullHeader + 'px';
        }

        // Quand on arrive à la dernière ou première ligne du tableau on le cache
        if (lastRowPos < 0 || firstRowPos > 0) {
          sticky.style.display = 'none';
        }
      }, supportsPassive ? {
        passive: true
      } : false);
      window.addEventListener('resize', function () {
        for (var _i2 = 0; _i2 < theadCells.length; _i2++) {
          var _cellWidth = theadCells[_i2].offsetWidth;
          newStickyCells[_i2].style.width = _cellWidth + 'px';
        }
      });
    },
    setStickyMobile: function setStickyMobile(target, index, data) {
      var stickyHeader = target.querySelector('.table__sticky__header');
      var header = document.querySelector('#ei_tpl_head');
      var tableHead = target.querySelector('.table__list .tns-slide-active thead');
      var firstRow = target.querySelector('.table__list .tns-slide-active tbody>tr:first-child');
      var lastRow = target.querySelector('.table__list .tns-slide-active tbody>tr:last-child');
      var sliderNav = target.querySelector('.tns-nav');
      var viewPortHeight = window.innerHeight;

      // On cache le header sticky par défaut et on récupère le texte de la bonne section
      stickyHeader.style.display = 'none';
      stickyHeader.innerHTML = data.thead[index];
      stickyHeader.style.width = tableHead.offsetWidth + 'px';
      var headerOffsetHeight = 0;
      if (header !== null) {
        headerOffsetHeight = header.offsetHeight;
      }
      var tableHeadPos = tableHead.getBoundingClientRect().top - headerOffsetHeight;
      var firstRowPos = firstRow.getBoundingClientRect().top - headerOffsetHeight;
      var lastRowPos = lastRow.getBoundingClientRect().top - headerOffsetHeight;

      // Quand on arrive au niveua du tableau on affiche le sticky
      if (tableHeadPos < 0) {
        stickyHeader.style.display = 'table';
        stickyHeader.style.position = 'fixed';
        stickyHeader.style.top = headerOffsetHeight + 'px';
      }

      // Quand on arrive à hauteur de la première ou de la dernière ligne on cache le sticky
      if (lastRowPos < 0 || firstRowPos > 0) {
        stickyHeader.style.display = 'none';
      }
      window.addEventListener('scroll', function () {
        var tableHeadPos = tableHead.getBoundingClientRect().top - headerOffsetHeight;
        var firstRowPos = firstRow.getBoundingClientRect().top - headerOffsetHeight;
        var lastRowPos = lastRow.getBoundingClientRect().top - headerOffsetHeight;
        var tableBottomPos = target.getBoundingClientRect().bottom;

        // Quand on arrive au niveua du tableau on affiche le sticky
        if (tableHeadPos < 0) {
          sliderNav.style.position = 'fixed';
          sliderNav.style.bottom = '50px';
          stickyHeader.style.display = 'table';
          stickyHeader.style.position = 'fixed';
          stickyHeader.style.top = headerOffsetHeight + 'px';
        }
        if (tableHeadPos > 0) {
          sliderNav.style.position = 'absolute';
          sliderNav.style.bottom = '-50px';
        }
        if (tableBottomPos < viewPortHeight) {
          sliderNav.style.position = 'absolute';
          sliderNav.style.bottom = '-50px';
        }

        // Quand on arrive à hauteur de la première ou de la dernière ligne on cache le sticky
        if (lastRowPos < 0 || firstRowPos > 0) {
          stickyHeader.style.display = 'none';
        }
      }, supportsPassive ? {
        passive: true
      } : false);
      window.addEventListener('resize', function () {
        stickyHeader.style.width = tableHead.offsetWidth + 'px';
      });
    },
    dropdown: function dropdown(target, tooltip) {
      var rowHeight = target.querySelector('th').offsetHeight;
      var tooltipHeight = tooltip.offsetHeight;
      target.style.transition = 'height 0.2s ease-in-out';
      tooltip.style.transition = 'opacity 0.3s ease-in-out .2s';
      target.style.height = rowHeight + tooltipHeight + 'px';
      tooltip.style.opacity = 0;
      target.addEventListener('click', function () {
        target.classList.contains('open') ? target.classList = '' : target.classList = 'open';
        rowHeight = target.querySelector('th').offsetHeight;
        tooltipHeight = tooltip.offsetHeight;
        if (target.classList.contains('open')) {
          target.style.height = rowHeight + tooltipHeight + 'px';
          tooltip.style.opacity = 1;
        } else {
          rowHeight = 0;
          target.style.height = rowHeight + tooltipHeight + 'px';
          tooltip.style.opacity = 0;
        }
      });
    },
    sliderDropdown: function sliderDropdown(tableList, slider) {
      //const targets = [...tableList.querySelectorAll('tbody tr')];//Non compatible avec IE11 et Babel (spread sur une nodelist)
      var targets = Array.prototype.slice.call(tableList.querySelectorAll('tbody tr'));
      targets.filter(function (el) {
        var row = el.querySelector('th');
        if (row !== null) {
          return el.querySelector('div[class*=tooltip]');
        }
      }).map(function (el) {
        var tooltip = el.querySelector('div[class*=tooltip]');
        var rowHeight = el.offsetHeight;
        var tooltipHeight = tooltip.offsetHeight;
        el.style.height = rowHeight + tooltipHeight + 'px';
        el.style.transition = 'height 0.2s ease-in-out';
        tooltip.style.opacity = 0;
        tooltip.style.transition = 'opacity 0.3s ease-in-out .2s';
        el.addEventListener('click', function () {
          el.classList.contains('open') ? el.className = '' : el.className = 'open';
          rowHeight = el.offsetHeight;
          tooltipHeight = tooltip.offsetHeight;
          if (el.classList.contains('open')) {
            el.style.height = rowHeight + tooltipHeight + 'px';
            tooltip.style.opacity = 1;
            setTimeout(function () {
              slider.updateSliderHeight();
            }, 250);
          } else {
            rowHeight = 0;
            el.style.height = rowHeight + tooltipHeight + 'px';
            tooltip.style.opacity = 0;
            setTimeout(function () {
              slider.updateSliderHeight();
            }, 250);
          }
        });
      });
    },
    init: function init() {
      table.onLoad();
    }
  };
  var dropdown = {
    onLoad: function onLoad() {
      // On récupère tous les dropdown sur la page
      //const allDropdown = [...document.querySelectorAll('[data-dropdown]')];//Non compatible avec IE11 et Babel (spread sur une nodelist)
      var allDropdown = Array.prototype.slice.call(document.querySelectorAll('[data-dropdown]'));

      // On vérifie s'il y a une map
      //const maps = [...document.getElementsByTagName('map')];//Non compatible avec IE11 et Babel (spread sur une nodelist)
      var maps = Array.prototype.slice.call(document.getElementsByTagName('map'));
      if (maps.length > 0) {
        dropdown.initMaps(maps);
      }
      if (allDropdown.length > 0) {
        // Pour chacun on ajoute l'event click/resize
        allDropdown.map(function (el) {
          var content = el.nextElementSibling;
          var contentHeight = content.scrollHeight;
          el.setAttribute('data-state', 'closed');
          if (el.getAttribute('data-state') === 'closed') {
            dropdown.onClose(content);
          }
          dropdown.onClick(el, content, contentHeight);
          dropdown.onResize(el, content);
        });
      }
    },
    initMaps: function initMaps(maps) {
      var areas = [];
      maps.map(function (el) {
        //  areas = [...el.getElementsByTagName('area')];//Non compatible avec IE11 et Babel (spread sur une nodelist)
        areas = Array.prototype.slice.call(el.getElementsByTagName('area'));
      });
      areas.map(function (el) {
        var currentStep = el.getAttribute('href').replace('#', '');
        var target = document.querySelector('dd[data-ref=' + currentStep + ']');
        var targetHeight = target.scrollHeight;
        el.addEventListener('mouseover', function () {
          dropdown.onOpen(target, targetHeight);
          var notTarget = areas.filter(function (area) {
            return area.getAttribute('href') !== '#' + currentStep;
          });
          notTarget.map(function (el) {
            var otherStep = el.getAttribute('href').replace('#', '');
            var questionToClose = document.querySelector('dd[data-ref=' + otherStep + ']');
            dropdown.onClose(questionToClose);
          });
        });
      });
    },
    onClick: function onClick(parent, target, targetHeight) {
      parent.addEventListener('click', function (e) {
        e.preventDefault();
        e.stopPropagation();

        // Si le data est déjà ouvert, on le passe à closed et vice versa
        parent.setAttribute('data-state', parent.getAttribute('data-state') === 'open' ? 'closed' : 'open');

        // Selon l'état on ouvre ou ferme
        if (parent.getAttribute('data-state') === 'open') {
          dropdown.onOpen(target, targetHeight);
        } else {
          dropdown.onClose(target);
        }
      });
    },
    onOpen: function onOpen(target, targetHeight) {
      target.classList.remove('closed');
      target.style.height = targetHeight + 'px';
      target.style.opacity = 1;
      target.style.visibility = 'visible';
      target.style.transition = 'height 0.25s ease-in-out, opacity .25s .25s';
    },
    onClose: function onClose(target) {
      target.classList.add('closed');
      target.style.height = 0;
      target.style.opacity = 0;
      target.style.visibility = 'hidden';
      target.style.transition = 'height 0.25s ease-in-out, opacity .25s .25s';
    },
    onResize: function onResize(parent, target) {
      window.addEventListener('resize', function () {
        if (parent.getAttribute('data-state') === 'open') {
          target.style.height = '';
          target.style.height = target.scrollHeight + 'px';
        }
      });
    },
    onHover: function onHover() {},
    init: function init() {
      dropdown.onLoad();
    }
  };
  var collapse = {
    onLoad: function onLoad() {
      //const collapsedEl = [...document.querySelectorAll('[data-collapse]')];//Non compatible avec IE11 et Babel (spread sur une nodelist)
      var collapsedEl = Array.prototype.slice.call(document.querySelectorAll('[data-collapse]'));
      collapsedEl.map(function (el) {
        var collapsedVal = el.dataset.collapse;
        var collapsedTrigger = el.dataset.tabElement;
        var collapsedTarget = el.dataset.tabpanelElement;
        //  const trigger = [...el.querySelectorAll(collapsedTrigger)];//Non compatible avec IE11 et Babel (spread sur une nodelist)
        var trigger = Array.prototype.slice.call(el.querySelectorAll(collapsedTrigger));
        //  const target = [...el.parentNode.querySelectorAll('[data-collapse] >' + collapsedTarget)];//Non compatible avec IE11 et Babel (spread sur une nodelist)
        var target = Array.prototype.slice.call(el.parentNode.querySelectorAll('[data-collapse] >' + collapsedTarget));
        if (collapsedVal) {
          target.map(function (el) {
            el.setAttribute('data-collapsed', 'yes');
            collapse.close(el);
          });
        }
        collapse.onClick(trigger, target);
      });
    },
    onClick: function onClick(trigger, target) {
      trigger.map(function (el, index) {
        el.addEventListener('click', function () {
          el.setAttribute('data-open', el.getAttribute('data-open') === 'yes' ? 'no' : 'yes');
          target[index].setAttribute('data-collapsed', target[index].getAttribute('data-collapsed') === 'yes' ? 'no' : 'yes');
          if (target[index].getAttribute('data-collapsed') === 'yes') {
            collapse.close(target[index]);
          } else {
            collapse.open(target[index]);
          }
        });
      });
    },
    open: function open(target) {
      var wrapper = document.querySelector('.video__wrapper');
      target.style.height = target.scrollHeight + 'px';
      if (wrapper) {
        wrapper.style.height = 'auto';
      }
      target.style.opacity = 1;
      target.style.visibility = 'visible';
      target.style.transition = 'height 0.25s ease-in-out, opacity .25s .25s';
    },
    close: function close(target) {
      target.style.height = 0;
      target.style.opacity = 0;
      target.style.visibility = 'hidden';
      target.style.transition = 'height 0.25s ease-in-out, opacity .25s .25s';
    },
    init: function init() {
      collapse.onLoad();
    }
  };

  //basé sur le composant accessible https://www.w3.org/TR/wai-aria-practices-1.1/examples/accordion/accordion.html
  //Comme la syntaxe Aria demande de créer des clefs uniques aria-controls et aria-labelledby, on se base sur ces ID pour gérer les actions
  //le wrapper doit avoir l'attribut data-accordion="true"
  //les "déclencheurs" doivent avoir les attributs data-trigger="true" id="URI_UNIQUE" tabindex="0" role="button" aria-expanded="false" aria-controls="URI_UNIQUE_DE_LA_CIBLE"
  //les "cible" c'est à dire les panneaux affichés/cachés doivent avoir les attributs id="URI_UNIQUE" role="region" data-target="true" aria-labelledby="URI_UNIQUE_DU_DECLENCHEUR" aria-hidden="true"
  //Exemples:
  //FAQ: https://tst-www.creditmutuel.fr/fr/particuliers/epargne/placement-financier-credit-mutuel.html https://sit-www.cic.fr/fr/particuliers/epargne/placement-financier-cic.html#form
  //Plié/déplié de vidéo : https://tst-www.creditmutuel.fr/fr/particuliers/comptes/confirmation-mobile.html https://sit-www.cic.fr/fr/particuliers/comptes/utiliser-safetrans.html
  //https://tst-www.creditmutuel.fr/fr/particuliers/comptes/utiliser-safetrans.html
  var RunAccordion = {
    onLoad: function onLoad() {
      //const accordionEl = [...document.querySelectorAll('[data-collapse]')];//Non compatible avec IE11 et Babel (spread sur une nodelist)
      var accordionList = Array.prototype.slice.call(document.querySelectorAll('[data-accordion]'));
      accordionList.forEach(function (accordion) {
        var triggerList = Array.prototype.slice.call(accordion.querySelectorAll('[data-trigger]'));
        triggerList.forEach(function (trigger) {
          trigger.addEventListener('click', function () {
            var triggerId = trigger.getAttribute('id');
            var targetId = trigger.getAttribute('aria-controls');
            if (triggerId && targetId) RunAccordion.clickTrigger(triggerId, targetId);
          });
        });
        accordion.addEventListener('keydown', function (event) {
          var target = event.target;
          var key = event.keyCode;

          // Is this coming from an accordion trigger
          if (target.hasAttribute('data-trigger')) {
            var triggerId = target.getAttribute('id');
            var targetId = target.getAttribute('aria-controls');
            var index = triggerList.indexOf(target);
            //13= Entrée, 32=Espace
            if (key == 13 || key == 32) {
              RunAccordion.clickTrigger(triggerId, targetId);
              event.preventDefault();
            }
            // 38 = Up, 40 = Down
            if (key == 38 || key == 40) {
              var direction = key == 40 ? 1 : -1;
              var length = triggerList.length;
              var newIndex = (index + length + direction) % length;
              triggerList[newIndex].focus();
              event.preventDefault();
            }
          }
        });
      });
    },
    keyPress: function keyPress() {
      RunAccordion.onLoad();
    },
    clickTrigger: function clickTrigger(triggerId, targetId) {
      var trigger = document.getElementById(triggerId);
      var target = document.getElementById(targetId);
      //Si la cible est visible on la cache et inversement
      if (target.getAttribute('aria-hidden') == 'true') {
        target.setAttribute('aria-hidden', 'false');
        trigger.setAttribute('aria-expanded', 'true');
      } else {
        target.setAttribute('aria-hidden', 'true');
        trigger.setAttribute('aria-expanded', 'false');
      }
    },
    init: function init() {
      RunAccordion.onLoad();
    }
  };
  var anchor = {
    onLoad: function onLoad() {
      var dom = {};
      if (window.location.href.indexOf('cic') > 0) {
        dom.headerMenuHeight = document.querySelector('#ei_tpl_menuPrincipal').offsetHeight;
      }
      dom.anchorsOrigin = document.querySelector('section.page__element.anchor');
      var anchorsCopy = dom.anchorsOrigin.cloneNode(true);
      var parentContainer = document.querySelector('.page__main');
      var anchors = Array.prototype.slice.call(document.querySelectorAll('[data-anchors]'));
      dom.anchorsList = Array.prototype.slice.call(dom.anchorsOrigin.querySelectorAll('.anchors__text'));
      dom.anchorsSticky = document.createElement('div');
      dom.headerHeight = document.querySelector('#ei_tpl_head').offsetHeight;
      dom.anchorsOriginPosition = dom.anchorsOrigin.getBoundingClientRect().top - dom.headerHeight;
      dom.anchors = anchors;
      dom.anchorsSticky.classList.add('anchorsCopy', 'hide');
      dom.anchorsSticky.appendChild(anchorsCopy);
      parentContainer.appendChild(dom.anchorsSticky);
      dom.anchorsStickyHeight = document.querySelector('.anchorsCopy').offsetHeight;
      dom.anchorsCopy = Array.prototype.slice.call(document.querySelector('.anchorsCopy').querySelectorAll('[data-target'));
      dom.anchorsEl = Array.prototype.slice.call(document.querySelector('section.page__element.anchor').querySelectorAll('[data-target'));
      anchor.onClick(dom);
      anchor.sticky(dom);
      anchor.onScroll(dom);
    },
    sticky: function sticky(dom) {
      if (window.location.href.indexOf('cic') > 0 && window.innerWidth >= 768) {
        dom.anchorsOriginPosition = dom.anchorsOrigin.getBoundingClientRect().top - (dom.headerHeight + dom.headerMenuHeight + dom.anchorsStickyHeight);
      } else {
        dom.anchorsOriginPosition = dom.anchorsOrigin.getBoundingClientRect().top - dom.headerHeight;
      }
      if (dom.anchorsOriginPosition <= 0) {
        dom.anchorsSticky.classList.add('sticky');
        dom.anchorsSticky.classList.remove('hide');
      }
      if (dom.anchorsOriginPosition >= 0) {
        dom.anchorsSticky.classList.remove('sticky');
        dom.anchorsSticky.classList.add('hide');
        dom.anchorsList.forEach(function (item) {
          document.activeElement.blur();
          item.classList.remove('active');
        });
      }
    },
    onScroll: function onScroll(dom) {
      window.addEventListener('scroll', function () {
        anchor.sticky(dom);

        //encore du crado rapidos vilain Carl
        function closestToZero(numbers) {
          if (!numbers.length) {
            return 0;
          }
          var closest = 0;
          var _loop4 = function _loop4(i) {
            if (closest === 0) {
              closest = numbers[i];
              dom.anchorsCopy[i].parentElement.classList.add('active');
              dom.anchorsCopy.forEach(function (el, index) {
                if (index !== i) {
                  el.parentElement.classList.remove('active');
                }
              });
            } else if (numbers[i] > 0 && numbers[i] <= Math.abs(closest)) {
              closest = numbers[i];
              dom.anchorsCopy[i].parentElement.classList.add('active');
              dom.anchorsCopy.forEach(function (el, index) {
                if (index !== i) {
                  el.parentElement.classList.remove('active');
                }
              });
            } else if (numbers[i] < 0 && -numbers[i] < Math.abs(closest)) {
              closest = numbers[i];
              dom.anchorsCopy[i].parentElement.classList.add('active');
              dom.anchorsCopy.forEach(function (el, index) {
                if (index !== i) {
                  el.parentElement.classList.remove('active');
                }
              });
            }
          };
          for (var i = 0; i < numbers.length; i++) {
            _loop4(i);
          }
          return closest;
        }
        var anchorsPos = [];
        dom.anchors.forEach(function (el) {
          if (window.location.href.indexOf('cic') > 0 && window.innerWidth >= 768) {
            anchorsPos.push(el.getBoundingClientRect().top - dom.headerHeight + dom.headerMenuHeight);
          } else {
            anchorsPos.push(el.getBoundingClientRect().top - dom.headerHeight);
          }
        });
        closestToZero(anchorsPos);
      });
    },
    onClick: function onClick(dom) {
      //crado mais flemme
      dom.anchorsCopy.map(function (el, index) {
        var target = document.querySelector("[data-anchors=".concat(el.dataset.target));
        el.addEventListener('click', function () {
          dom.anchorsCopy.map(function (anchor, i) {
            if (i !== index) {
              anchor.parentElement.classList.remove('active');
            }
          });
          el.parentElement.classList.add('active');
          var headerMenuHeight = document.querySelector('#ei_tpl_menuPrincipal').offsetHeight;
          var anchorHeight = document.querySelector('.page__element.anchor').offsetHeight;
          var headerHeight = document.querySelector('#ei_tpl_head').offsetHeight;
          if (window.location.href.indexOf('cic') > 0) {
            window.scroll(0, target.getBoundingClientRect().top + window.scrollY - (headerHeight + headerMenuHeight + anchorHeight));
          } else {
            window.scroll(0, target.getBoundingClientRect().top + window.scrollY - (headerHeight + anchorHeight));
          }
        });
      });
      dom.anchorsEl.map(function (el, index) {
        var target = document.querySelector("[data-anchors=".concat(el.dataset.target));
        el.addEventListener('click', function () {
          dom.anchorsEl.map(function (anchor, i) {
            if (i !== index) {
              anchor.parentElement.classList.remove('active');
            }
          });
          el.parentElement.classList.add('active');
          var anchorHeight = document.querySelector('.page__element.anchor').offsetHeight;
          var headerHeight = document.querySelector('#ei_tpl_head').offsetHeight;
          if (window.innerWidth >= 768) {
            if (window.location.href.indexOf('cic') > 0) {
              var headerMenuHeight = document.querySelector('#ei_tpl_menuPrincipal').offsetHeight;
              window.scroll(0, target.getBoundingClientRect().top + window.scrollY - (headerHeight + headerMenuHeight + anchorHeight));
            } else {
              window.scroll(0, target.getBoundingClientRect().top + window.scrollY - (headerHeight + anchorHeight));
            }
          } else {
            window.scroll(0, target.getBoundingClientRect().top + window.scrollY - headerHeight);
          }
        });
      });
    },
    init: function init() {
      var isAnchors = document.querySelector('.anchors__list');
      if (isAnchors !== null) {
        anchor.onLoad();
      } else {
        return;
      }
    }
  };
  var formAnchor = {
    onLoad: function onLoad() {
      var target = document.querySelector('[data-anchor]') || null;
      if (target === null) {
        return;
      } else {
        var targetPos = target.getBoundingClientRect().top;
        var header = document.querySelector('.header__prospection') || null;
        var headerPos = header !== null ? header.offsetHeight : 0;
        var triggers = document.querySelectorAll("[href='#".concat(target.dataset.anchor, "']"));
        triggers.forEach(function (item) {
          return item.addEventListener('click', function (e) {
            e.preventDefault();
            window.scrollTo(0, targetPos - headerPos);
          });
        });
      }
    },
    init: function init() {
      formAnchor.onLoad();
    }
  };
  var urlParam = {
    onLoad: function onLoad() {
      var url = window.location.href;
      var urlParam = url.split('#')[1];
      var anchor = document.querySelector("[data-anchors=".concat(urlParam) || null);
      console.log(urlParam);
      console.log(anchor);
      if (anchor !== null) {
        var anchorHeight = document.querySelector('.page__element.anchor').offsetHeight || null;
        var anchorPos = anchor.getBoundingClientRect().top;
        var header = document.querySelector('#ei_tpl_head') || null;
        var menu = document.querySelector('#ei_tpl_menuPrincipal') || null;
        var headerPos = header.offsetHeight + menu.offsetHeight || 0;
        console.log(anchorPos, headerPos, anchorHeight);
        window.scrollTo(0, anchorPos - (headerPos + anchorHeight));
        anchor.click();
      } else {
        return;
      }
    },
    init: function init() {
      urlParam.onLoad();
    }
  };
  document.addEventListener('DOMContentLoaded', function () {
    table.init();
    dropdown.init();
    anchor.init();
    collapse.init();
    RunAccordion.init();
    formAnchor.init();
    urlParam.init();
    if (typeof objectFitPolyfill !== 'undefined' && typeof objectFitPolyfill === 'function') {
      objectFitPolyfill();
    }
  });
})();